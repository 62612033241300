const images = document.getElementsByClassName('responsive-image');
for (let i = 0; i < images.length; i++) {
    let tmpWidth = 0,
        tmpHeight = 0,
        sources = images[i].getElementsByTagName('source'),
        img = images[i].getElementsByTagName('img');
    for (let j = 0; j < sources.length; j++) {
        if (window.matchMedia(sources[j].media).matches) {
            tmpWidth = sources[j].dataset.width;
            tmpHeight = sources[j].dataset.height;
            break;
        }
    }
    if(tmpWidth > 0 && tmpHeight > 0 ) {
        img[0].setAttribute('width', tmpWidth.toString());
        img[0].setAttribute('height', tmpHeight.toString());
    }
}

$(function () {
    $('html').removeClass('no-js');
    // $('.gallery-slider').slick({
    //     slidesToShow: 1,
    //     centerMode: true,
    //     variableWidth: true,
    //     centerPadding: '25%',
    //     infinite: true,
    //     arrows: true,
    //     responsive: [
    //         {
    //             breakpoint: 769,
    //             settings: {
    //                 centerPadding: '10%',
    //                 dots: true,
    //             }
    //         },
    //         {
    //             breakpoint: 568,
    //             settings: {
    //                 arrows: false,
    //                 centerPadding: '6%',
    //                 dots: true,
    //             }
    //         },
    //     ]
    // });
    // $('.hero-slider .slider').slick({
    //     arrows: true,
    //     dots: true,
    //     autoplay: true,
    //     autoplaySpeed: 3000,
    //     fade: true,
    //     cssEase: 'linear',
    //     customPaging: function (slider, i) {
    //         return '<a>' + (i + 1) + '</a>';
    //     },
    //     responsive: [
    //         {
    //             breakpoint: 768,
    //             settings: {
    //                 arrows: false,
    //                 autoplay: false,
    //             }
    //         },
    //     ]
    // });
    // $('.text-slider .slider').slick({
    //     arrows: true,
    //     autoplay: true,
    //     autoplaySpeed: 3000,
    //     slidesToShow: 3,
    //     responsive: [
    //         {
    //             breakpoint: 1200,
    //             settings: {
    //                 arrows: false,
    //                 dots: true,
    //             }
    //         },
    //         {
    //             breakpoint: 720,
    //             settings: {
    //                 slidesToShow: 2,
    //                 arrows: false,
    //                 dots: true,
    //             }
    //         },
    //         {
    //             breakpoint: 540,
    //             settings: {
    //                 slidesToShow: 1,
    //                 arrows: false,
    //                 dots: true,
    //             }
    //         },
    //     ]
    // });


    //SCROLL DOWN TO NEXT SECTION BUTTON IN HERO SLIDER
    $('.scroll-btn').on('click', function () {
        let hero = $('.frame-type-wvier_hero_slider').next('div');
        let navOffset = $('.navbar').outerHeight();

        $('html, body').animate({
            scrollTop: hero.offset().top - navOffset
        }, 700);
    });

    $('.scrolldown').on('click', function () {
        let hero = $('.frame-type-wvier_abo_header').next('div');
        let navOffset = $('.navbar').outerHeight();

        $('html, body').animate({
            scrollTop: hero.offset().top - navOffset
        }, 700);
    });

    //SYNCH BOTH NAVIGATIONS FOR IMAGE TABS NAVIGATIONS & SCROLL TO SECtion
    $('.tabs-image .nav-tabs li a').click(function (e) {
        //get selected href
        let href = $(this).attr('href');
        //set all nav tabs to inactive
        $('.tabs-image .nav-tabs li a').removeClass('active');
        //get all nav tabs matching the href and set to active
        $('.tabs-image .nav-tabs li a[href="' + href + '"]').addClass('active');
        //active tab
        $('.tabs-image .tab-pane').removeClass('active');
        $('.tabs-image .tab-pane' + href).addClass('active');

        //scroll to content
        let tabsSection = $(this).closest('.tabs-image').find('.tab-content');
        let navOffset = $('.navbar').outerHeight();

        $('html, body').animate({
            scrollTop: tabsSection.offset().top - navOffset
        }, 700);
    });

    if (window.matchMedia('(max-width: 1024.98px)').matches) {
        $('.hero-accordion .btn').on('click', function () {
            $(this).closest('.hero-image').toggleClass('show-overlay');
            $('html').toggleClass('no-scrolling');
            $('body').toggleClass('no-scrolling');
        });

        $('.hero-accordion-overlay').on('click', function () {
            $(this).closest('.hero-image').toggleClass('show-overlay');
            $('html').toggleClass('no-scrolling');
            $('body').toggleClass('no-scrolling');
            $(this).closest('.hero-image').find('.btn-accordion').attr("aria-expanded", "false");
            $(this).closest('.hero-image').find('.collapse').toggleClass('show');
        });
    }

    $('.embed-responsive-16by9 .play ').on('click', function (){
        $(this).closest('.embed-responsive-16by9').find('video').get(0).play();
        $(this).closest('.embed-responsive-16by9').find('.pause').toggleClass('d-none')
        $(this).toggleClass('d-none')
    });

    $('.embed-responsive-16by9 .pause ').on('click', function (){
        $(this).closest('.embed-responsive-16by9').find('video').get(0).pause();
        $(this).closest('.embed-responsive-16by9').find('.play').toggleClass('d-none')
        $(this).toggleClass('d-none')
    });
});

//Check if input is filled and add floating class
function checkForInput(element) {
    // element is passed to the function ^

    const groupElement = $(element).closest('.form-group-floating');

    if ($(element).val().length > 0) {
        groupElement .addClass('input-filled');
    } else {
        groupElement .removeClass('input-filled');
    }
}

// The lines below are executed on page load
$('.form-group-floating input').each(function() {
    checkForInput(this);
});
// The lines below (inside) are executed on change & keyup
$('.form-group-floating input').on('change keyup', function() {
    checkForInput(this);
});
