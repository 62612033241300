$(function () {
    $('.navbar-toggler').on('click', function () {
        if ($('html').hasClass('menu-open')) {
            $('html').removeClass('menu-open');
        } else {
            $('html').addClass('menu-open');
        }
    });

    $('.dropdown-close-btn').on('click', function () {
        if ($('#' + $(this).data('close')).length) {
            var $link = $('#' + $(this).data('close'));
            $link.parent().removeClass('show');
            $('.navbar').removeClass('no-scroll');
            $link.parent().find('> .dropdown-toggle').attr('aria-expanded', 'false');
            $link.parent().find('> .dropdown-menu').removeClass('show');

        }
    });

    $('.btn-close.dropdown-close').on('click', function () {
        closeDropdown();
    });

    $('.close-btn-overlay').on('click', function () {
        closeDropdown();
    });

    function closeDropdown() {
        $('.second-level').removeClass('show');
        $('.nav-item.dropdown').removeClass('show');
        $('.navbar-logo-wrapper').removeClass('lg-menu-open');
    }

    /**
     * Solution to enable links on dropdowns, the link will only be triggered
     * if the dropdown is visible. On touch devices you will need to double
     * click on a dropdown, the first click will open the menu.
     */

    function navbarPointerOver($element) {
        if ($('.navbar-toggler').is(':hidden') && !$element.hasClass('open')) {
            $element.parent().parent().find('li').removeClass('show');
            $element.addClass('show');
            $element.find('> .dropdown-toggle').attr("aria-expanded", "true");
            $element.find('> .dropdown-menu').addClass('show');
        }
    }

    function navbarPointerLeave($element) {
        if ($('.navbar-toggler').is(':hidden')) {
            $element.removeClass('show');
            $element.find('> .dropdown-toggle').attr("aria-expanded", "false");
            $element.find('> .dropdown-menu').removeClass('show');
        }
    }

    $(document).on('pointerover', 'li.dropdown-hover', function (e) {
        if (e.originalEvent.pointerType === "mouse") {
            navbarPointerOver($(this));
        }
    });

    $(document).on('mouseenter', 'li.dropdown-hover', function () {
        if (/^((?!chrome|android).)*safari/i.test(navigator.userAgent)) {
            navbarPointerOver($(this));
        }
    });

    $(document).on('pointerleave', 'li.dropdown-hover', function (e) {
        if (e.originalEvent.pointerType === "mouse") {
            navbarPointerLeave($(this));
        }
    });

    $(document).on('mouseleave', 'li.dropdown-hover', function () {
        if (/^((?!chrome|android).)*safari/i.test(navigator.userAgent)) {
            navbarPointerLeave($(this));
        }
    });


    $(document).on('click', '.dropdown-toggle', function (e) {
        if ($(this).parent().hasClass('dropdown') && !$(this).parent().hasClass('show')) {
            $('.navbar-logo-wrapper').addClass('lg-menu-open');
            $(this).parent().parent().parent().find('.dropdown').removeClass('show');
            $('.navbar').removeClass('no-scroll');
            $(this).parent().parent().parent().find('.dropdown').find('> .dropdown-toggle').attr('aria-expanded', 'false');
            $(this).parent().parent().parent().find('.dropdown').find('> .dropdown-menu').removeClass('show');
            $(this).parent().addClass('show');
            $('.navbar').addClass('no-scroll');
            $(this).parent().find('> .dropdown-toggle').attr('aria-expanded', 'true');
            $(this).parent().find('> .dropdown-menu').addClass('show');
            e.stopImmediatePropagation();
            e.preventDefault();
            return false;
        }
    });

    //close active dropdown on click
    if (window.matchMedia("(min-width: 1025px)").matches) {
        $(document).on('click', '.navbar-nav > .nav-item.dropdown.show > .dropdown-toggle', function (e) {
            e.preventDefault();
            $('.second-level').each(function () {
                $(this).removeClass('show');
            });
            $('.navbar-logo-wrapper').removeClass('lg-menu-open');
            $(this).closest('.nav-item').removeClass('show');
        })
    }

});
